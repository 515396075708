<template>
    <div>
        <center>
            <h4>Tambah Pesan</h4>
        </center>
        <form v-on:submit.prevent="saveMessage" enctype="multipart/form-data">
            <label for="">Hari ke</label>
            <input type="number" class="form-control" v-model="form.day" min="0" required>
            <br>
            <label for="">Jenis Pesan</label>
            <select v-model="form.message_type" class="form-control">
                <option value="text">Text / Media</option>
                <!-- <option value="button">Button Message</option>
                <option value="list">List Message</option> -->
            </select>
            <br>
            <div v-if="form.message_type == 'text'">
                <label for="">Text</label>
                <CustomTextArea v-model="form.text"></CustomTextArea>
                <br>
                <label> File (docx, xlsx, pdf, jpg, png, mp4) </label>
                <input ref="file" type="file" @change="onFileChange" class="form-control">
                <br>
                <div v-if="this.form.old_file" class="card">
                    <div class="card-body">
                        <center>
                            <b>Document</b>
                            <br>
                            <a :href="this.form.old_file" target="_blank"> Open File </a>
                        </center>
                    </div>
                </div>
            </div>
            <div v-else-if="form.message_type == 'button'" class="card thick-border">
                <div class="card-body">
                    <h5 style="text-align: center;">BUTTON RESPONSE</h5>
                    <label> File (docx, xlsx, pdf, jpg, png, mp4) [Akan Dikirimkan Secara Terpisah]</label>
                    <input ref="file" type="file" @change="onFileChange" accept=".jpg,.jpeg,.png" class="form-control">
                    <br>
                    <div v-if="this.form.old_file" class="card">
                        <div class="card-body">
                            <center>
                                <b>Document</b>
                                <br>
                                <a :href="this.form.old_file" target="_blank"> Open File </a>
                            </center>
                        </div>
                    </div>
                    <br>
                    <label for="">Teks Button</label>
                    <CustomTextArea v-model="form.text"></CustomTextArea>
                    <br>
                    <button type="button" class="btn btn-primary btn-sm" v-on:click="addButton"
                        style="margin:5px">Tambah
                        Button</button>
                    <button type="button" class="btn btn-danger btn-sm" v-on:click="deleteButton"
                        style="margin:5px">Hapus
                        Button</button>

                    <br>
                    <div v-for="btn in button" v-bind:key="btn.id">
                        <br>
                        <div class="card thick-border">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="">Tipe Button</label>
                                        <select v-model="btn.type" class="form-control">
                                            <option value="quickReplyButton">Quick Reply (Tulisan Biasa)</option>
                                            <option value="urlButton">URL</option>
                                            <option value="callButton">Call Nomor Telepon</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <div>
                                            <label for="">Display Text (Tulisan Pada Button)</label>
                                            <input type="text" class="form-control" v-model="btn.displayText" required>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div v-if="btn.type == 'urlButton'">
                                    <label for="">URL</label>
                                    <input type="text" class="form-control" v-model="btn.url" placeholder="https://"
                                        required>
                                </div>
                                <div v-else-if="btn.type == 'callButton'">
                                    <label for="">Nomor Telepon</label>
                                    <input type="text" class="form-control" v-model="btn.phoneNumber"
                                        placeholder="08xxxxxxxx" required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <label for="">Footer</label>
                    <input type="text" v-model="form.footer" placeholder="Footer" class="form-control">
                </div>
                <div style="margin: 5px">
                    <i>* File / Gambar akan dikirimkan terlebih dahulu (secara terpsisah)</i>
                    <br>
                    <i>* Android Menampilkan Maksimal 3 Button</i>
                    <br>
                    <i>* Whatsapp Web Menampilkan Maksimal 5 Button</i>
                    <br>
                    <i>* IOS Unlimited Button</i>
                </div>
            </div>
            <div v-else-if="form.message_type == 'list'" class="card thick-border">
                <div class="card-body">
                    <h5 style="text-align: center;">LIST MESSAGE</h5>
                    <br>
                    <div class="row">
                        <div class="col-md-6">
                            <label> File (docx, xlsx, pdf, jpg, png, mp4) [Akan Dikirimkan Secara Terpisah]</label>
                            <input ref="file" type="file" @change="onFileChange" accept=".jpg,.jpeg,.png"
                                class="form-control">
                            <br>
                            <div v-if="this.form.old_file" class="card">
                                <div class="card-body">
                                    <center>
                                        <b>Document</b>
                                        <br>
                                        <a :href="this.form.old_file" target="_blank"> Open File </a>
                                    </center>
                                </div>
                            </div>
                            <br>
                            <label for="">Judul List Message</label>
                            <input type="text" class="form-control" v-model="list.title" placeholder="Title" required>
                            <br>
                            <label for="">Deskripsi List Message</label>
                            <CustomTextArea v-model="form.text"></CustomTextArea>
                            <br>
                            <label for="">Button Text</label>
                            <input type="text" class="form-control" v-model="list.buttonText" placeholder="Klik Disini"
                                required>
                            <br>
                            <button type="button" class="btn btn-primary btn-sm" v-on:click="addListSection"
                                style="margin:5px">Tambah Pilihan</button>
                            <button type="button" class="btn btn-danger btn-sm" v-on:click="deleteListSection"
                                style="margin:5px">Hapus Pilihan</button>

                            <br>
                            <div v-for="ls in list.sections" v-bind:key="ls.id">
                                <br>
                                <div class="card thick-border">
                                    <div class="card-body">
                                        <label for="">Judul Pilihan</label>
                                        <input type="text" class="form-control" v-model="ls.title" placeholder="Title"
                                            required>
                                        <br>
                                        <label for="">Deskripsi Pilihan</label>
                                        <CustomTextArea v-model="ls.description"></CustomTextArea>
                                        <!-- <input type="text" class="form-control" v-model="ls.description"
                                            placeholder="Description" required> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img style="padding:15px" src="@/assets/img/listmsg/1.png" />
                            <img style="padding:15px" src="@/assets/img/listmsg/2.png" width="371px" />

                        </div>
                    </div>
                </div>
                <div style="margin: 5px">
                    <i>* File / Gambar akan dikirimkan terlebih dahulu (secara terpsisah)</i>
                </div>
            </div>
            <br>
            <label for="">Jam Pengiriman (Sesuai Timezone Anda)</label>
            <input type="time" class="form-control" v-model="form.jadwal" required>
            <br>
            <b>Anda bisa menggunakan dynamic string untuk generate random string, contoh : (halo|hai|pagi|siang)</b>
            <br>
            <b>Variable yang bisa anda pakai : {{ available_variable }}</b>
            <br>
            <b>Anda dapat memanggil URL untuk unsubscribe dengan {unsubscribe}</b>
            <br><br>
            <button class="btn btn-success btn-block">Simpan</button>
        </form>
    </div>
</template>


<script>
    import CustomTextArea from "@/components/module/CustomTextarea.vue";

    export default {
        props: ['campaign'],
        components: {
            CustomTextArea,
        },
        data() {
            return {
                form: {
                    day: "",
                    jadwal: "",
                    text: "",
                    file: "",
                    footer: "",
                    message_type: "text",
                    old_file: null
                },
                page: {
                    one: true,
                    two: false,
                    three: false
                },
                multiselect: {
                    value: [],
                    options: []
                },
                progress: 0,
                contact: [],
                group: [],
                button: [],
                list: {
                    title: "",
                    buttonText: "",
                    sections: [],
                    section_index: 0
                },
                button_index: 0,
                loading: {
                    isGroupLoading: false,
                },
                deviceGroups: [],
                available_variable: ''
            };
        },
        watch: {
            'form.sender': {
                handler() {
                    this.deviceGroups = []
                },
                deep: true
            },
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);
            //this.getWelcomeMessage()
            this.variableHelper()
        },
        methods: {
            variableHelper() {
                let regis = this.campaign.registrasi
                regis = regis.split("#")

                for (let i = 1; i < regis.length; i++) {
                    this.available_variable += '[' + regis[i] + ']'

                    if (regis[i + 1]) {
                        this.available_variable += ', '
                    }
                }
            },
            async getWelcomeMessage() {
                try {
                    let campaignMessage = await this.$store.dispatch("campaign_welcome_message", this.campaign)
                    campaignMessage = campaignMessage.data.data.welcomeMessage
                    this.form.message_type = campaignMessage.message_type
                    this.form.text = campaignMessage.text
                    this.form.old_file = campaignMessage.file

                    if(campaignMessage.message_type == "button"){
                        let buttonData = JSON.parse(campaignMessage.data)

                        for (let i = 0; i < buttonData.length; i++) {
                            const element = buttonData[i];
                            this.form.footer = element.footer
                            
                            this.button.push({
                                type: element.button_type,
                                displayText: element.display_text,
                                url: element.button_action,
                                phoneNumber: element.button_action,
                                id: "id-" + (this.button_index + 1)
                            })
                            this.button_index += 1

                        }
                    }else if(campaignMessage.message_type == "list"){
                        let listData = JSON.parse(campaignMessage.data)
                        this.list = listData
                    }
                } catch (err) {
                    console.log(err)

                }
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            onFileChange(e) {
                const selectedFile = e.target.files[0];
                this.form.file = selectedFile;
            },
            addButton() {
                this.button.push({
                    type: "quickReplyButton",
                    displayText: "",
                    url: "",
                    phoneNumber: "",
                    id: "id-" + (this.button_index + 1)
                })

                this.button_index += 1

                this.$toast.success("Tambah Button Sukses");
            },
            deleteButton() {
                if (this.button_index > 0) {
                    this.button.splice(-1)
                    this.button_index -= 1

                    this.$toast.success("Hapus Button Sukses");
                }
            },
            addListSection() {
                this.list.sections.push({
                    title: "",
                    description: "",
                    id: "id-" + (this.list.section_index + 1)
                })

                this.list.section_index += 1

                this.$toast.success("Tambah Pilihan Sukses");
            },
            deleteListSection() {
                if (this.list.section_index > 0) {
                    this.list.sections.splice(-1)
                    this.list.section_index -= 1

                    this.$toast.success("Hapus Pilihan Sukses");
                }
            },
            async saveMessage() {
                try {
                    const formData = new FormData();
                    let url = ""
                    if (this.form.file) {
                        formData.append('file', this.form.file)
                        let response = await this.$store.dispatch('cdn_upload', formData)
                        url = response.data.data.url
                    }

                    let message = {
                        message_type: this.form.message_type,
                        text: this.form.text,
                        file: url,
                        footer: this.form.footer
                    }

                    if (this.form.message_type == "button") {
                        message.button = []

                        for (let i = 0; i < this.button.length; i++) {
                            let button_to_push = {
                                button_id: this.button[i].id.split("-")[1],
                                button_type: this.button[i].type,
                                display_text: this.button[i].displayText,
                            }

                            if (this.button[i].type == "urlButton") {
                                button_to_push.button_action = this.button[i].url
                            } else if (this.button[i].type == "callButton") {
                                button_to_push.button_action = this.button[i].phoneNumber
                            }

                            message.button.push(button_to_push)
                        }
                    } else if (this.form.message_type == "list") {
                        message.list = {
                            title: this.list.title,
                            buttonText: this.list.buttonText,
                            sections: this.list.sections
                        }
                    }

                    await this.$store.dispatch("campaign_message_create", {id: this.campaign.id, day: parseInt(this.form.day), jadwal: this.form.jadwal, ...message})

                    this.toggleModal("add_message")
                    this.$root.$emit("refresh_table", true)
                    this.$toast.success("Tambah Pesan Sukses");
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.progress = 0
                }
            },
        },
    };
</script>

<style scoped>

</style>

<style scoped>
    .thick-border {
        border: 2px solid #6c6c6c !important;
    }
</style>