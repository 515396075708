<template>
    <div>
        <center>
            <h4>Tambah Campaign</h4>
        </center>
        <br>
        <form v-on:submit.prevent="addCampaign" enctype="multipart/form-data">
            <label> Pengirim </label>
            <multiselect v-model="form.device" tag-placeholder="Pilih device"
                placeholder="Pilih device" label="name" track-by="id" :options="device"
                :multiple="false"></multiselect>
            <br>
            <label for="">Nama Campaign</label>
            <input type="text" class="form-control" v-model="form.name" required>
            <br>
            <label for="">Nomor Device</label>
            <input type="text" class="form-control" v-model="form.number" required>
            <br>
            <label for="">Syntax Registrasi</label>
            <input type="text" class="form-control" v-model="form.syntax" placeholder="Contoh : registrasi#nama#alamat" required>
            <div v-if="syntax_explain.length > 0">
                <br>
                <table class="table table-bordered">
                    <tr v-for="(value, index) in syntax_explain" v-bind:key="index">
                        <td v-if="index == 0" width="20%">Kata Kunci</td>
                        <td v-else  width="20%">Variabel</td>
                        <td width="80%">{{ value }}</td>
                    </tr>
                </table>
            </div>
            <br>
            <!-- <label for="">Pesan Registrasi</label>
            <CustomTextArea v-model="form.message"></CustomTextArea>
            <i>Anda bisa memanggil variabel menggunakan [] (Bracket)</i>
            <br>
            <i>Variabel Tersedia : {{ available_variable }}</i>
            <br> -->
            <br>
            <button class="btn btn-success btn-block">Simpan</button>
        </form>
    </div>
</template>

<script>
    //import moment from 'moment'
    import Multiselect from 'vue-multiselect'

    export default {
        props: ['device'],
        components: {
            Multiselect,
        },
        data() {
            return {
                form: {
                    device: [],
                    name: "",
                    number: "",
                    syntax: "",
                    message:""
                },
                syntax_old: "",
                syntax_explain: [],
                available_variable: ""
            };
        },
        watch: {
            form : {
                handler(val){
                    if(val.syntax != this.syntax_old){
                        let syntax = val.syntax
                        let split = syntax.split("#")

                        this.syntax_explain = split
                        this.available_variable = ''

                        for(let i = 1;i < split.length;i++){
                            this.available_variable += '[' + split[i] + ']'
                            
                            if(split[i+1]){
                                this.available_variable += ', '
                            }
                        }

                        this.syntax_old = val.syntax
                    }
                },
                deep: true
            },
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            onFileChange(e) {
                this.form.avatar = e.target.files[0]
            },
            async addCampaign(){
                try{
                    let create_campaign = await this.$store.dispatch('campaign_create', this.form)
                    this.$toast.success(create_campaign.data.message)
                    this.$root.$emit("toggle_modal", "new_campaign")
                    this.$root.$emit("refresh_table", true)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>

<style scoped>
    

    table {
        border: 1px solid;
    }
</style>