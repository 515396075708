<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between ">
                        <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">Detail Campaign</h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <!-- <a v-on:click="toggleModal('new_campaign')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-upload fs-16"></i>
                                Import Anggota
                            </a> -->
                            <a  v-on:click="toggleModal('import')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-upload fs-16"></i>
                                Import Anggota
                            </a>
                            <a v-on:click="exportMembers()" class="btn px-15 btn-secondary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-download fs-16"></i>
                                Export Anggota
                            </a>
                            <download-csv ref="exportMember" :data="campaignMembers" :name="campaignName"
                                style="display: none"></download-csv>
                            <!-- <a v-on:click="toggleModal('new_campaign')" class="btn px-15 btn-success text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-message fs-16"></i>
                                Broadcast
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <div class="tab-wrapper">
                            <div class="atbd-tab tab-horizontal">
                                <ul class="nav nav-tabs vertical-tabs" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.one }"
                                            v-on:click="changePage('one')">Detail</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.six }"
                                            v-on:click="changePage('six')">Pesan Registrasi</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.seven }"
                                            v-on:click="changePage('seven')">Custom Form</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.two }"
                                            v-on:click="changePage('two')">Pesan</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.three }"
                                            v-on:click="changePage('three')">Anggota</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.four }"
                                            v-on:click="changePage('four')">History Pesan</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.five }"
                                            v-on:click="changePage('five')">Integrasi</a>
                                    </li>
                                </ul>
                                <div class="tab-content" v-if="!isLoading">
                                    <div class="tab-pane fade" :class="{ 'show active': page.one }" id="tab-v-1"
                                        role="tabpanel" aria-labelledby="tab-v-1-tab">
                                        <Detail :device="device" :id="campaign_id" :campaign="campaign" />
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'show active': page.two }" id="tab-v-2"
                                        role="tabpanel" aria-labelledby="tab-v-2-tab">
                                        <button v-on:click="toggleModal('add_message')" class="btn btn-success">Tambah Pesan</button>
                                        <br>
                                        <br>
                                        <CampaignMessageTable :id="campaign_id" />
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'show active': page.three }" id="tab-v-3"
                                        role="tabpanel" aria-labelledby="tab-v-3-tab">
                                        <CampaignUserTable :id="campaign_id" :campaign="campaign"/>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'show active': page.four }" id="tab-v-4"
                                        role="tabpanel" aria-labelledby="tab-v-4-tab">
                                        <CampaignHistoryTable :id="campaign_id" :campaign="campaign"></CampaignHistoryTable>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'show active': page.five }" id="tab-v-5"
                                        role="tabpanel" aria-labelledby="tab-v-5-tab">
                                        <Integration :device="device" :id="campaign_id" :campaign="campaign" />
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'show active': page.six }" id="tab-v-5"
                                        role="tabpanel" aria-labelledby="tab-v-5-tab">
                                        <RegistMessage :campaign="campaign"></RegistMessage>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'show active': page.seven }" id="tab-v-5"
                                        role="tabpanel" aria-labelledby="tab-v-5-tab">
                                        <Form :campaign="campaign"></Form>
                                    </div>
                                </div>
                                <div class="tab-content" v-else>
                                    <center>
                                        <div class="spinner-border spinner" role="status"></div>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <modal name="add_message" width="70%" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddMessage :campaign="campaign"></AddMessage>
                </div>
            </modal>
            <modal name="import" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <ImportCampaignMembers :campaign="campaign" />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import Detail from "@/components/forms/campaign/Detail.vue";
    import CampaignMessageTable from "@/components/forms/campaign/CampaignMessageTable.vue";
    import CampaignUserTable from "@/components/forms/campaign/CampaignUserTable.vue";
    import CampaignHistoryTable from "@/components/forms/campaign/CampaignHistoryTable.vue";
    import RegistMessage from "@/components/forms/campaign/RegistMessage.vue";
    import Form from "@/components/forms/campaign/Form.vue";
    import AddMessage from "@/components/forms/campaign/AddMessage.vue";
    import Integration from "@/components/forms/campaign/Integration.vue";
    import ImportCampaignMembers from "@/components/forms/campaign/ImportMembers.vue";

    //import SendBlast from "@/components/forms/outgoing/SendBlast.vue";
    //import moment from 'moment'

    export default {
        components: {
            Detail,
            CampaignMessageTable,
            CampaignUserTable,
            CampaignHistoryTable,
            RegistMessage,
            Form,
            AddMessage,
            Integration,
            ImportCampaignMembers
            //SendBlast
        },
        data() {
            return {
                campaign_id: this.$route.query.id,
                page: {
                    one: true,
                    two: false,
                    three: false,
                    four: false,
                    five: false,
                    six: false, 
                    seven: false
                },
                isLoading: true,
                device: [],
                campaign: {},
                campaignMembers: [],
                campaignName: ""
            };
        },
        async beforeMount() {

        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.getAllData()
        },
        methods: {
            async getAllData() {
                await this.getDevice({
                    status: "all"
                }).then(data => {
                    this.device = data
                })
                await this.getCampaignDetail({
                    id: this.campaign_id
                }).then(data => {
                    this.campaign = data
                    this.campaignName = "whacenter_campaign_member_" + this.campaign.name + ".csv"
                })
                this.isLoading = false
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                return res.data.data.devices
            },
            async getCampaignDetail(param) {
                let res = await this.$store.dispatch("campaign_detail", param);
                return res.data.data.campaign
            },
            changePage(page) {
                this.page.one = false
                this.page.two = false
                this.page.three = false
                this.page.four = false
                this.page.five = false
                this.page.six = false
                this.page.seven = false

                if (page == "one") this.page.one = true
                if (page == "two") this.page.two = true
                if (page == "three") this.page.three = true
                if (page == "four") this.page.four = true
                if (page == "five") this.page.five = true
                if (page == "six") this.page.six = true
                if (page == "seven") this.page.seven = true
            },
            async exportMembers(){
                await this.$store.dispatch("campaign_user_list", {id: this.campaign_id})
                        .then((response) => {
                            let variabel = this.parseVar(this.campaign.registrasi)
                            for (let i = 0; i < response.data.data.users.length; i++) {
                                const element = response.data.data.users[i];

                                let m = {
                                    "Nomor Whatsapp": this.formatNumber(element.number)
                                }

                                let d = JSON.parse(element.data)
                                for (let j = 0; j < variabel.length; j++) {
                                    const element = variabel[j];
                                    m[element] = d[j]
                                }

                                m["Bergabung Pada"] = this.toDateTime(element.joined_at)

                                this.campaignMembers.push(m)
                            }
                        })
                        .then(() => {
                            this.$refs.exportMember.$el.click()
                        })
                        .then(() => {
                            this.campaignMembers = []
                        })
            },
            formatNumber(number){
                if(number.charAt(0) == "0"){
                    number = "62" + number.substring(1)
                }
                return number
            },
            parseVar(inputString) {
                let parts = inputString.split('#');
                parts.shift();

                let nonEmptyParts = parts.filter(part => part.trim() !== '');

                let capitalizedParts = nonEmptyParts.map(part => {
                    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
                });

                return capitalizedParts;
            },
            toDateTime(unixTimestamp){
                const dateObject = new Date(unixTimestamp);

                const year = dateObject.getFullYear();
                const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Tambah 1 karena bulan dimulai dari 0
                const day = String(dateObject.getDate()).padStart(2, '0');

                const formattedDate = `${year}/${month}/${day}`;

                return formattedDate
            }
        },
    };
</script>

<style scoped>
    .nav-link {
        cursor: pointer !important;
    }

    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }
</style>