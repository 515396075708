<template>
    <div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
            :isLoading.sync="table.isLoading" :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }" :columns="table.columns" :rows="table.rows">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                    <a v-on:click="editCampaignMessage(props.row.id)" class="btn btn-primary text-white margin5">Detail</a>
                    <a v-on:click="deleteCampaignMessage(props.row.id)" class="btn btn-danger text-white margin5">
                        Hapus
                    </a>
                </span>
                <span v-else-if="props.column.field == 'text'">
                    <div v-if="props.row.message_type == 'text'">
                        {{ cutString(props.row.text) }}
                        <div v-if="props.row.file">
                            <br>
                            <div class="card">
                                <div class="card-body">
                                    <center>
                                        <b>Document</b>
                                        <br>
                                        <a :href="props.row.file" target="_blank"> Open File </a>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="props.row.message_type == 'button'">
                        <div class="card thick-border">
                            <div class="card-body">
                                <h6 style="text-align: center;">BUTTON MESSAGE</h6>
                                <hr>
                                {{ cutString(props.row.text) }}
                                <br>
                                <div v-if="props.row.file">
                                    <br>
                                    <div class="card">
                                        <div class="card-body">
                                            <center>
                                                <b>Document</b>
                                                <br>
                                                <a :href="props.row.file" target="_blank"> Open File </a>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div v-for="button in props.row.parsedButton" v-bind:key="button.index">
                                    <div class="card thick-border">
                                        <div class="card-body" style="padding:10px">
                                            <div v-if="button.button_type == 'quickReplyButton'">
                                                <!-- <p style="font-size:10px" class="float-right">[QUICK REPLY BUTTON]</p>
                                                <br> -->
                                                <h6 style="text-align:center">
                                                    {{ button.display_text }}
                                                </h6>
                                            </div>
                                            <div v-else-if="button.button_type == 'urlButton'">
                                                <!-- <p style="font-size:10px" class="float-right">[URL BUTTON]</p>
                                                <br> -->
                                                <h6 style="text-align:center">
                                                    <a :href="button.button_action" target="_blank"><i class="fas fa-globe fs-16"></i>&nbsp;{{ button.display_text }}</a>
                                                </h6>
                                            </div>
                                            <div v-else-if="button.button_type == 'callButton'">
                                                <!-- <p style="font-size:10px" class="float-right">[CALL BUTTON]</p>
                                                <br> -->
                                                <h6 style="text-align:center">
                                                    <a :href="'tel:' +button.button_action"><i class="fas fa-phone fs-16"></i>&nbsp;{{ button.display_text }}</a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                                <a style="color:black;font-size: 12px;">{{ props.row.footer }}</a>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="props.row.message_type == 'list'">
                        <div class="card thick-border">
                            <div class="card-body">
                                <h6 style="text-align: center;">LIST MESSAGE</h6>
                                <hr>
                                <b>{{ props.row.parsedList.title }}</b>
                                <p>{{ cutString(props.row.text) }}</p>
                                <button class="btn btn-outline-success btn-block">{{ props.row.parsedList.buttonText }}</button>
                                <br>
                                <div v-for="s in props.row.parsedList.sections" v-bind:key="s.index" style="padding-top:10px">
                                    <div class="card thick-border">
                                        <div class="card-body" style="padding:5px">
                                            <b>{{ s.title }}</b>
                                            <p>{{ s.description }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>

        <modal name="edit_message" width="70%" :height="'auto'" :adaptive="true" :scrollable="true">
            <div style="margin: 20px">
                <EditMessage :messageID="messageID" :campaignID="id"></EditMessage>
            </div>
        </modal>
    </div>
</template>

<script>
    import EditMessage from "@/components/forms/campaign/EditMessage.vue";

    export default {
        props: ['device', 'id'],
        components: {
            EditMessage,
        },
        data() {
            return {
                messageID: 0,
                table: {
                    total_records: 100,
                    isLoading: true,
                    columns: [{
                            label: "Hari ke ",
                            field: "day",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "10%",
                        },
                        {
                            label: "Pesan",
                            field: "text",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "50%",
                        },
                        {
                            label: "Jadwal",
                            field: "jadwal",
                            filterOptions: {
                                enabled: false,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Action",
                            field: "action",
                            sortable: false,
                            width: "20%",
                        },
                    ],
                    rows: [],
                    serverParams: {
                        columnFilters: {},
                        sort: [{
                            field: "",
                            type: "",
                        }, ],
                        page: 1,
                        perPage: 10,
                    },
                },
            };
        },
        beforeDestroy(){
            this.$root.$off("refresh_table", this.fnRefreshTable);
        },
        mounted() {
            this.fnRefreshTable = () => {
                this.loadItems();
            }
            this.$root.$on("refresh_table", this.fnRefreshTable);
            
            this.loadItems();
        },
        methods: {
            cutString(string){
                if(string == null || string == "") return ""
                
                if(string.length > 40){
                    string = string.slice(0, 40) + "..."
                }

                return string
            },
            async deleteCampaignMessage(id) {
                try {
                    let response = await this.$store.dispatch("campaign_message_delete", {
                        campaign_id: this.id,
                        id: id
                    });
                    this.loadItems();
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error("Server error");
                }
            },
            updateParams(newProps) {
                this.table.serverParams = Object.assign({},
                    this.table.serverParams,
                    newProps
                );
            },
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({
                    perPage: params.currentPerPage
                });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params.sortType,
                        field: this.table.columns[params.columnIndex].field,
                    }, ],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            async loadItems() {
                try {
                    let response = await this.getFromServer(this.table.serverParams);
                    this.table.total_records = response.data.data.total_records;

                    let messages = response.data.data.messages;
                    for (let i = 0; i < messages.length; i++) {
                        if(messages[i].message_type == "button"){
                            let parse = JSON.parse(messages[i].data)
                            messages[i].parsedButton = parse
                            messages[i].footer = parse?.[0].footer
                        }else if(messages[i].message_type == "list"){
                            let parse = JSON.parse(messages[i].data)
                            messages[i].parsedList = parse
                        }
                    }
                    this.table.rows = messages;
                } catch (err) {
                    this.$toast.error("Too many request");
                }
            },
            async getFromServer(param) {
                param.id = this.id
                return await this.$store.dispatch("campaign_message_list", param);
            },
            async editCampaignMessage(id){
                this.messageID = id
                this.toggleModal("edit_message")
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
        },
    };
</script>

<style scoped>
    .margin5 {
        margin: 5px;
    }
</style>