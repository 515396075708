<template>
    <div>
        <form v-on:submit.prevent="newSendProtocol" enctype="multipart/form-data">
            <div v-if="!form.multipleJadwal">
                <label for="">Pengirim</label>
                <multiselect v-model="form.sender" tag-placeholder="Pilih Device Pengirim" 
                    placeholder="Pilih Device Pengirim" label="name" :custom-label="customLabel" track-by="id"
                    :options="deviceConnected" @input="getCampaigndevice($event)" :loading="form.isLoading">
                </multiselect>
                <br>
            </div>
			
            <label for="">Tujuan Pesan</label>
			<multiselect v-model="multiselect.value" tag-placeholder="Cari Campaign"
                    placeholder="Cari Campaign" label="label" track-by="id" :options="multiselect.options"
                    :multiple="false" :internal-search="false" :loading="multiselect.isLoading"  ref="tujuanPesan"></multiselect>
            
           
            <br>
            <label for="">Jenis Pesan</label>
            <select v-model="form.message_type" class="form-control">
                <option value="text">Text / Media</option>
               
            </select>
            <br>
            <div v-if="form.message_type == 'text'">
                <label for="">Text</label>
                <CustomTextArea v-model="form.text"></CustomTextArea>
                <br>
                <label> File (docx, xlsx, pdf, jpg, png, mp4) </label>
                <input ref="file" type="file" @change="onFileChange" class="form-control">
            </div>
            <div v-else-if="form.message_type == 'button'" class="card thick-border">
                <div class="card-body">
                    <h5 style="text-align: center;">BUTTON RESPONSE</h5>
                    <label> File (docx, xlsx, pdf, jpg, png, mp4) [Akan Dikirimkan Secara Terpisah]</label>
                    <input ref="file" type="file" @change="onFileChange" accept=".jpg,.jpeg,.png" class="form-control">
                    <br>
                    <label for="">Teks Button</label>
                    <CustomTextArea v-model="form.text"></CustomTextArea>
                    <br>
                    <button type="button" class="btn btn-primary btn-sm" v-on:click="addButton"
                        style="margin:5px">Tambah Button</button>
                    <button type="button" class="btn btn-danger btn-sm" v-on:click="deleteButton"
                        style="margin:5px">Hapus Button</button>

                    <br>
                    <div v-for="btn in button" v-bind:key="btn.id">
                        <br>
                        <div class="card thick-border">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="">Tipe Button</label>
                                        <select v-model="btn.type" class="form-control">
                                            <option value="quickReplyButton">Quick Reply (Tulisan Biasa)</option>
                                            <option value="urlButton">URL</option>
                                            <option value="callButton">Call Nomor Telepon</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <div>
                                            <label for="">Display Text (Tulisan Pada Button)</label>
                                            <input type="text" class="form-control" v-model="btn.displayText" required>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div v-if="btn.type == 'urlButton'">
                                    <label for="">URL</label>
                                    <input type="text" class="form-control" v-model="btn.url" placeholder="https://"
                                        required>
                                </div>
                                <div v-else-if="btn.type == 'callButton'">
                                    <label for="">Nomor Telepon</label>
                                    <input type="text" class="form-control" v-model="btn.phoneNumber"
                                        placeholder="08xxxxxxxx" required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <label for="">Footer</label>
                    <input type="text" v-model="form.footer" placeholder="Footer" class="form-control">
                </div>
                <div style="margin: 5px">
                    <i>* File / Gambar akan dikirimkan terlebih dahulu (secara terpsisah)</i>
                    <br>
                    <i>* Android Menampilkan Maksimal 3 Button</i>
                    <br>
                    <i>* Whatsapp Web Menampilkan Maksimal 5 Button</i>
                    <br>
                    <i>* IOS Unlimited Button</i>
                </div>
            </div>
            <div v-else-if="form.message_type == 'list'" class="card thick-border">
                <div class="card-body">
                    <h5 style="text-align: center;">LIST MESSAGE</h5>
                    <br>
                    <div class="row">
                        <div class="col-md-6">
                            <label> File (docx, xlsx, pdf, jpg, png, mp4) [Akan Dikirimkan Secara Terpisah]</label>
                            <input ref="file" type="file" @change="onFileChange" accept=".jpg,.jpeg,.png"
                                class="form-control">
                            <br>
                            <label for="">Judul List Message</label>
                            <input type="text" class="form-control" v-model="list.title" placeholder="Title" required>
                            <br>
                            <label for="">Deskripsi List Message</label>
                            <CustomTextArea v-model="form.text"></CustomTextArea>
                            <br>
                            <label for="">Button Text</label>
                            <input type="text" class="form-control" v-model="list.buttonText" placeholder="Klik Disini"
                                required>
                            <br>
                            <button type="button" class="btn btn-primary btn-sm" v-on:click="addListSection"
                                style="margin:5px">Tambah Pilihan</button>
                            <button type="button" class="btn btn-danger btn-sm" v-on:click="deleteListSection"
                                style="margin:5px">Hapus Pilihan</button>

                            <br>
							
                            <div v-for="ls in list.sections" v-bind:key="ls.id">
                                <br>
                                <div class="card thick-border">
                                    <div class="card-body">
                                        <label for="">Judul Pilihan</label>
                                        <input type="text" class="form-control" v-model="ls.title" placeholder="Title" required>
                                        <br>
                                        <label for="">Deskripsi Pilihan</label>
                                        <CustomTextArea v-model="ls.description"></CustomTextArea>
                                        <!-- <input type="text" class="form-control" v-model="ls.description" placeholder="Description" required> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img style="padding:15px" src="@/assets/img/listmsg/1.png" />
                            <img style="padding:15px" src="@/assets/img/listmsg/2.png" width="371px" />

                        </div>
                    </div>
                </div>
                <div style="margin: 5px">
                    <i>* File / Gambar akan dikirimkan terlebih dahulu (secara terpsisah)</i>
                </div>
            </div>
            <br>
            <input v-model="form.send_now" class="checkbox" type="checkbox" id="sendnow">
            <label for="sendnow">
                <span for="sendnow" class="checkbox-text">&nbsp;Kirim pesan sekarang</span>
            </label>
            <br>
            <br>
            <div v-if="!form.send_now">
                <input v-model="form.multipleJadwal" class="checkbox" type="checkbox" id="multipleJadwal">
                <label for="multipleJadwal">
                    <span for="multipleJadwal" class="checkbox-text">&nbsp;Aktifkan multiple jadwal <span class="badge badge-success">NEW</span>
                </span>
                </label>
                <br>
                <div v-if="!form.multipleJadwal">
                    <label for="">Tanggal & Jam</label>
                    <v-date-picker class="inline-block h-full" v-model="form.date" mode="dateTime" is24hr>
                        <template v-slot="{ inputValue, togglePopover }">
                            <div>
                                <input @focus="togglePopover()" :value="inputValue" class="form-control" />
                            </div>
                        </template>
                    </v-date-picker>
                </div>
                <div v-else>
                    <div class="card thick-border">
                        <div class="card-body">
                            <h5 style="text-align: center;">MULTIPLE JADWAL</h5>
                            <br>
                            <div class="row">
                                <div class="col-md-6">
                                    <a v-on:click="addJadwal" class="btn btn-block btn-success text-white" style="display: inline-flex; margin: 5px">
                                        Tambah Jadwal
                                    </a>
                                </div>
                                <div class="col-md-6">
                                    <a v-on:click="deleteJadwal" class="btn btn-block btn-danger text-white" style="display: inline-flex; margin: 5px">
                                        Hapus Jadwal
                                    </a>
                                </div>
                            </div>
                            <br>
                            <div id="var">
                                <div v-for="vars in form.multipleDate" :key="vars.id" >
                                    <div class="card thick-border">
                                        <div class="card-body">
                                            <h5 style="text-align: center;">Jadwal {{ vars.id }}</h5>
                                            <br>
                                            <label for="">Pengirim</label>
                                            <multiselect v-model="vars.sender" tag-placeholder="Pilih Device Pengirim"
                                                placeholder="Pilih Device Pengirim" label="name" :custom-label="customLabel" track-by="id"
                                                :options="device">
                                            </multiselect>
                                            <br>
                                            <label for="">Tanggal & Jam</label>
                                            <v-date-picker class="inline-block h-full" v-model="vars.date" mode="dateTime" is24hr>
                                                <template v-slot="{ inputValue, togglePopover }">
                                                    <div>
                                                        <input @focus="togglePopover()" :value="inputValue" class="form-control" />
                                                    </div>
                                                </template>
                                            </v-date-picker>
                                            <br>
                                            <label for="">Limit</label>
                                            <input type="number" class="form-control" v-model="vars.limit" required>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                            </div>
                            <i>*Jika nomor tujuan broadcast melebihi limit yang di-setting, maka sisa nomor tujuan akan ikut dikirimkan pada jadwal terakhir</i>
                        </div>
                    </div>
                </div>

                <br>
                <br>
            </div>
            <label> Delay Interval (Detik) </label>
            <div class="row">
                <div class="col-md-5">
                    <input type="number" v-model="form.delay_start" placeholder="Dari" class="form-control">
                </div>
                <div class="col-md-2">
                    <center>
                        <b style="text-align: center;">SAMPAI</b>
                    </center>
                </div>
                <div class="col-md-5">
                    <input type="number" v-model="form.delay_end" placeholder="Sampai" class="form-control">
                </div>
            </div>
            <br>
            <div v-if="form.target_method == 'contact'">
                <i>- Anda bisa panggil nama whatsapp menggunakan [[NAME]] </i>
                <br>
                <i>- Anda bisa menggunakan dynamic string untuk generate random string, contoh : (halo|hai|pagi|siang)
                </i>
                <br>
                <i>- Anda bisa panggil nama kontak menggunakan {nama} </i>
                <br>
                <i>- Anda bisa panggil variabel menggunakan {var1}, {var2}, {var3}, dst </i>
                <br>
            </div>
            <div v-else>
                <i>- Anda bisa panggil nama whatsapp menggunakan [[NAME]] </i>
                <br>
            </div>
            <br>
            <input v-model="form.advanceSetting" class="checkbox" type="checkbox" id="advanceSetting">
            <label for="advanceSetting">
                <span for="advanceSetting" class="checkbox-text">&nbsp;Advance Setting  <span class="badge badge-success">NEW</span></span>
            </label>
            <div v-if="form.advanceSetting">
                <div class="card thick-border">
                    <div class="card-body">
                        <label for="">Skip</label>
                        <input type="number" class="form-control" v-model="form.skip">
                        <br>
                        <label for="">Limit</label>
                        <input type="number" class="form-control" v-model="form.limit">
                    </div>
                </div>
            </div>
            <br>
            <br>
            <button class="btn btn-primary btn-block">Kirim Pesan</button>
        </form>
        <modal name="send_progress" :height="'auto'" :adaptive="true" :scrollable="true" style="overflow-y: auto;">
            <div style="margin: 20px;text-align:center">
                <div class="spinner-border spinner" role="status"></div>
                <br>
                <h2>{{ progress }}%</h2>
                <br>
                <h3>SEDANG MEMPERSIAPKAN PESAN</h3>
                <p>Jangan Tutup Halaman Ini</p>
            </div>
        </modal>
    </div>
</template>

<script>
    import CustomTextArea from "@/components/module/CustomTextarea.vue";
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'

    export default {
        props: ['device'],
        components: {
            CustomTextArea,
            Multiselect
        },
        data() {
            return {
                form: {
                    sender: null,
                    receiver: "",
                    text: "",
                    file: "",
                    date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    footer: "",
                    message_type: "text",
                    target_method: "contact",
                    send_now: true,
                    delay_start: 30,
                    delay_end: 60,
                    multipleJadwal: false,
                    multipleDate: [],
                    advanceSetting: false,
                    limit: -1,
                    skip: 0,
					isLoading:true
                },
                page: {
                    one: true,
                    two: false,
                    three: false
                },
                multiselect: {
                    isLoading: false,
                    value: [],
                    options: []
                },
                progress: 0,
                contact: [],
                campaign: [],
                button: [],
                list: {
                    title: "",
                    buttonText: "",
                    sections: [],
                    section_index: 0
                },
                button_index: 0,
                loading: {
                    isGroupLoading: false,
                },
                deviceGroups: [],
				deviceConnected:[],
				allCampaign:[]
            };
        },
        watch: {
            'form.sender' : {
                handler(){
                    this.deviceGroups = []
                },
                deep: true
            },
        },
        mounted() {
            this.$root.$on("toggle_modal", (param) => {
                this.toggleModal(param);
            });
			for (let i = 0; i < this.device.length; i++) {
				if(this.device[i].status == 'connected')
					this.deviceConnected.push(this.device[i])
			}
            
           this.getAllCampaign()
        },
        methods: {
            sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            customLabel({
                name,
                status
            }) {
                return `${name} (${status})`
            },
			getCampaigndevice(device){
				
				let option = []
				for (let i = 0; i < this.campaign.length; i++) {
					if(device.id == this.campaign[i].device_id){
						option.push({
						//	id: this.campaign[i].group_id,
                            id: this.campaign[i].id,
							label: this.campaign[i].name
						})
					}
					
                }
				
				this.multiselect.value = null
				this.multiselect.options = option
				
              
			
			},
            async getAllCampaign() {
                let campaign = await this.$store.dispatch("campaign_list_all")
            
                campaign = campaign.data.data.campaigns
                //console.log(campaign)
                this.campaign = campaign
				this.form.isLoading =false
				
             
            },
            resetInput() {
                this.$refs.file.value = null;
                this.form = {
                    sender: "",
                    receiver: "",
                    text: "",
                    file: "",
                    date: "",
                    footer: "",
                    message_type: "text",
                    target_method: "contact",
                    send_now: true,
                    delay_start: 30,
                    delay_end: 60
                }
                this.list =  {
                    title: "",
                    buttonText: "",
                    sections: [],
                    section_index: 0
                }
                this.button = []
                this.button_index = 0
                this.multiselect.value = []
            },
            onFileChange(e) {
                const selectedFile = e.target.files[0];
                this.form.file = selectedFile;
            },
            renderMessage(variable, text) {
                let nama = JSON.parse(variable['name'])
				
                text = text.replaceAll("{nama}", nama[0])

                if(variable?.data){
                    let variableArray = JSON.parse(variable.data ?? [])

                    for (let i = 0; i < variableArray.length; i++) {
                        const element = variableArray[i];
                        text = text.replaceAll("{var" + element.id + "}", element.value)
                    }
                }

                return this.dynamicString(text)
            },
            dynamicString(message) {
                let match;
                while ((match = message.match(/\(([^()]*)\)/)) !== null) {
                    let split = match[1].split("|");
                    if (split.length <= 1) {
                    return message; // Hindari pengulangan tak terbatas jika tidak ada pilihan yang valid.
                    }
                    const randomNumber = (min, max) => {
                    min = Math.ceil(min);
                    max = Math.floor(max);
                    return Math.floor(Math.random() * (max - min + 1)) + min;
                    };

                    let random = randomNumber(0, split.length - 1);
                    let replacer = split[random];
                    message = message.replace(match[0], replacer);
                }

                return message;
            },
            async buildSelectedContact(fileURL = null){
                try{
                    let messages = []
                    let selected = this.multiselect.value
					let obj = JSON.parse(JSON.stringify(selected))
                    let grup_id = obj.id
                    
					let res
                      
                        res = await this.$store.dispatch("group_daftar_member", {
                            group_id: grup_id,
							});
                        res = res.data.data.members ?? []
                         
						//console.log(res)
						
                        for (let i = 0; i < res.length; i++) {
                            if(!res[i]?.number){
                                continue
                            }

                            let message_to_push = {
                                message_type: this.form.message_type,
                                to: res[i].number,
                                text: await this.renderMessage(res[i], this.form.text),
                                file: fileURL,
                                footer: this.form.footer
                            }

                            messages.push(message_to_push)
                        }
                    

                    return messages
                }catch(err){
                    console.log(err)
                    return []
                }
            },
            async devideArray(array, limit = -1, skip = -1, delayStart, delayEnd, setDelay = true){
                limit = parseInt(limit)
                skip = parseInt(skip)
                let i = (skip == -1) ? 0 : skip
                let len = (limit == -1) ? array.length : skip + limit
                let delay = 0

                let finalArray = []
                for (i; i < len; i++) {
                    if(!array[i]) continue
                    
                    if(setDelay){
                        array[i].delay = delay
                        delay += Math.ceil(Math.random() * (parseInt(delayEnd) - parseInt(delayStart)) + parseInt(delayStart))
                    }
                    
                    finalArray.push(array[i])
                }

                return finalArray
            },
            async newSendProtocol(){
				
                this.progress = 0
                this.toggleModal("send_progress")

                try{
                    let fileURL

                    if (this.form.date == "") {
                        this.form.date = moment().utc().valueOf()
                    } else {
                        this.form.date = moment(this.form.date).utc().valueOf()
                    }

                    if (this.form.file) {
                        this.progress = 15
                        const formData = new FormData();
                        formData.append('file', this.form.file)
                        let response = await this.$store.dispatch('cdn_upload', formData)
                        fileURL = response.data.data.url
                    } 

                    let messages = []

                   this.progress = 50
                  
                    //GET ALL CONTACT
                    messages = await this.buildSelectedContact(fileURL)
                    

                    if(messages.length == 0){
						
                        this.$toast.error("Tidak ada tujuan pesan")
                        this.toggleModal("send_progress")
                        this.progress = 0
						
                        return
                    }
					
					
					
					
					

                    if (this.$store.state.auth.package.message_quota != -1 && messages.length > this.$store.state.auth.package.message_quota) {
                        this.$toast.error("Kuota pesan tidak mencukupi")
                        this.toggleModal("send_progress")
                        this.progress = 0
                        return
                    }

                    if(this.form.limit != -1 || this.form.skip != 0){
                        messages = await this.devideArray(messages, this.form.limit, this.form.skip, 0, 0, false)
                    }

                    this.progress = 70

                    let finalMessage = []
                    if(this.form.multipleJadwal){
                        let skip = 0
                        for (let i = 0; i < this.form.multipleDate.length; i++) {
                            let limit = parseInt(this.form.multipleDate[i].limit)

                            if(i == this.form.multipleDate.length - 1){
                                limit = -1
                            }

                            finalMessage.push({
                                device_id: this.form.multipleDate[i].sender.id,
                                schedule: moment(this.form.multipleDate[i].date).utc().valueOf(),
                                broadcast: await this.devideArray(messages, limit, skip, this.form.delay_start, this.form.delay_end)
                            })
                            skip += limit
                        }
                    }else{
                        finalMessage.push({
                            device_id: this.form?.sender?.id,
                            schedule: this.form.date,
                            broadcast: await this.devideArray(messages, -1, -1, this.form.delay_start, this.form.delay_end)
                        })
                    }

                    this.progress = 90

                    let sendMessage
                    for (let i = 0; i < finalMessage.length; i++) {
                        sendMessage = await this.$store.dispatch('outgoing_send', finalMessage[i])
                    }

                    this.progress = 100
                    this.resetInput()
                    this.$toast.success(sendMessage.data.message)
                    this.toggleModal("send_progress")
                    this.$root.$emit("refresh_package", true)
                    this.progress = 0
					this.toggleModal("broadcast_campaign")
                }catch(err){
                    this.resetInput()
                    this.toggleModal("send_progress")
                    this.$root.$emit("refresh_package", true)
                    this.progress = 0
                    this.$toast.error(err?.response?.data?.message)
                }
            },
            chunkArrayInGroups(arr, size) {
                var myArray = [];
                for (let i = 0; i < arr.length; i += size) {
                    myArray.push(arr.slice(i, i + size));
                }

                let lastDelay = []
                for (let i = 0; i < myArray.length; i++) {
                    lastDelay.push(myArray[i][myArray[i].length - 1].delay)
                }

                for (let i = 0; i < myArray.length; i++) {
                    if (i != myArray.length - 1) {
                        for (let j = 0; j < myArray[i + 1].length; j++) {
                            myArray[i + 1][j].delay -= lastDelay[i]
                        }
                    }
                }

                return myArray;
            },
            checkSender(){
                return this.form.sender ? true : false
            },
            checkDeviceGroups(){
                return this.deviceGroups.length
            },
           
            addJadwal() {
                this.form.multipleDate.push({
                    id: this.form.multipleDate.length + 1,
                    sender: this.form.sender,
                    date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    limit: null
                });
            },
            deleteJadwal() {
                this.form.multipleDate.splice(-1)
            },
        },
    };
</script>

<style scoped>

</style>

<style scoped>
    .thick-border {
        border: 2px solid #6c6c6c !important;
    }
</style>
