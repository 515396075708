<template>
    <div>
        <center>
            <h4>FORM</h4>
        </center>
        <br>
        <form v-on:submit.prevent="updateForm">
            <label for="">URL Form</label>
            <br>
            <input type="text" class="form-control" style="max-width: 90%;display: inline-block;" :value="form.url" disabled>
            <button type="button" v-on:click="copyURL" class="btn btn-secondary btn-block" style="max-width: 10%;display: inline-block;">Salin</button>
            <br>
            <br>
            <label for="">Tipe Form</label>
            <select v-model="form.form_type" class="form-control">
                <option value="0">Direct</option>
                <option value="1">Alihkan ke Chat Whatsapp</option>
            </select>
            <i>*Direct artinya begitu user klik submit pada form, data langsung tersimpan</i>
            <br>
            <i>*Alihkan ke Chat Whatsapp artinya begitu user klik submit pada form, akan diarahkan ke nomor whatsapp campaign, kemudian begitu user kirim pesan, data campaign akan tersimpan</i>
            <br>
            <br>
            <div v-if="form.form_type == 0">
                <label>Link Custom Redirect Setelah Registrasi Berhasil</label>
                <br>
                <input placeholder="https://example.com" v-model="campaign.welcome_message" type="text" class="form-control">
                <br>
                <br>
            </div>
            
            <label for="">Header Form</label>
            <vue-editor v-model="form.header" />
            <br>
            <br>
            <br>
            <button class="btn btn-primary btn-block" type="submit">Simpan Settingan Form</button>
            <br>
            <a class="btn btn-secondary btn-block" v-on:click="toggleModal('embed_form')">Embed Form</a>
        </form>

        <modal name="embed_form" :height="'auto'" :adaptive="true" :scrollable="true">
            <div style="margin: 20px">
                <a class="btn btn-secondary btn-block btn-sm" v-on:click="copyToClipboard">Copy to Clipboard</a>
                <br>
                <pre><code id="textToBecopied">{{ embedFormHTML }}</code></pre>
            </div>
        </modal>
    </div>
</template>


<script>
    //import moment from 'moment'
    import { VueEditor } from "vue2-editor";

    export default {
        props: ['device', 'id', 'campaign'],
        components: {
            VueEditor
        },
        data() {
            return {
                form: {
                    id: "",
                    device: [],
                    name: "",
                    number: "",
                    syntax: "",
                    message: "",
                    url: "",
                    form_type: 0,
                    header: "",
                },
                syntax_old: "",
                syntax_explain: [],
                available_variable: "",
                quill_disabled: true,
                embedFormHTML: ""
            };
        },
        watch: {
            form : {
                handler(val){
                    if(val.syntax != this.syntax_old){
                        let syntax = val.syntax
                        let split = syntax.split("#")

                        this.syntax_explain = split
                        this.available_variable = ''

                        for(let i = 1;i < split.length;i++){
                            this.available_variable += '[' + split[i] + ']'
                            
                            if(split[i+1]){
                                this.available_variable += ', '
                            }
                        }

                        this.syntax_old = val.syntax
                    }
                },
                deep: true
            },
        },
        mounted() {
            this.$root.$on("toggle_modal", (param) => {
                this.toggleModal(param);
            });

            this.getCampaign()
            this.buildEmbedForm()
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            copyToClipboard() {
                const copyText = document.getElementById('textToBecopied').textContent
                const textArea = document.createElement('textarea')
                textArea.textContent = copyText
                document.body.append(textArea)
                textArea.select()
                document.execCommand('copy')

                this.$toast.success("Embed Form berhasil disalin")
            },
            buildEmbedForm(){
                let input = ""

                if(this.campaign.form_type == 0){
                    input += `<label for="">NOMOR WHATSAPP*</label>
<input type="number" name="number" class="whacenter-input" required>
<br>`
                }

                let split = this.campaign.registrasi.split("#")
                split.shift()
                for (let i = 0; i < split.length; i++) {
                    input += `<label for="">`+ split[i].toUpperCase() +`*</label>
<input type="text" name="data[]" class="whacenter-input" required>
<br>`
                }

                this.embedFormHTML = `<style>
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;1,400&display=swap);

.whacenter-div{
    font-family: "Poppins", sans-serif;
}
.whacenter-form { 
    max-width:420px; 
    margin:50px auto; 
}

.whacenter-input {
    color:black;
    font-weight:500;
    font-size: 18px;
    border-radius: 5px;
    line-height: 22px;
    background-color: transparent;
    transition: all 0.3s;
    padding: 13px;
    margin-bottom: 15px;
    width:100%;
    box-sizing: border-box;
    outline:0;
}

.whacenter-btn{
    width: 100%;
    background:#28a745;
    border-radius:5px;
    border:0;
    cursor:pointer;
    color:white;
    font-size:24px;
    padding-top:10px;
    padding-bottom:10px;
    transition: all 0.3s;
    margin-top:-4px;
    font-weight:700;
}
.whacenter-btn:hover { background:#1e8034; }
</style>

<form method="post" action="`+  process.env.VUE_APP_SUBSCRIBE_URL + '/subscribe/' + this.campaign.id +`" id="webform" class="whacenter-form"> 
` + input + `
<button type="submit" name="submit" formtarget="_blank" class="whacenter-btn">DAFTAR SEKARANG</button>
</form>`
            },
            async getCampaign() {
                let res = this.campaign

                this.form.id = res.id
                this.form.name = res.name
                this.form.number = res.number
                this.form.syntax = res.registrasi
                this.form.message = res.welcome_message
                this.form.url = process.env.VUE_APP_SUBSCRIBE_URL + '/subscribe/' + res.id
                this.form.form_type = res.form_type
                this.form.header = res.header ? this.decodeEscapedHTML(res.header) : ""

                // for(let i = 0;i < this.device.length;i++){
                //     if(this.device[i].id == res.device_id){
                //         this.form.device = this.device[i]
                //     }
                // }

                this.quill_disabled=false
            },
            async updateCampaign(){
                try{
                    let data = {
                        id: this.form.id,
                        device: this.form.device,
                        number: this.form.number,
                        message: this.form.message
                    }

                    let update_campaign = await this.$store.dispatch('campaign_update', data)
                    this.$toast.success(update_campaign.data.message)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            async updateForm(){
                try{
                    let data = {
                        id: this.form.id,
                        success_page: this.campaign.welcome_message,
                        form_type: parseInt(this.form.form_type),
                        header: this.form.header,
                    }

                    let update_campaign = await this.$store.dispatch('campaign_update_form', data)
                    this.$toast.success(update_campaign.data.message)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            copyURL(){
                navigator.clipboard.writeText(this.form.url)
                this.$toast.success("URL berhasil disalin")
            },
            decodeEscapedHTML(str){
                return str.replaceAll(/&lt;/g,'<').replaceAll(/&gt;/g,'>').replaceAll(/&amp;/g,'&');
            }
        },
    };
</script>

<style scoped>
    @import "~vue-wysiwyg/dist/vueWysiwyg.css";
    

    table {
        border: 1px solid;
    }

    .editor {
        height: 100%;
    }
</style>