<template>
    <div>
        <form v-on:submit.prevent="updateCampaign">
            <label> Tracking Option </label>
            <br>
            <select v-model="form.tracking_option" class="form-control">
                <option value="none" selected>Tanpa Tracking</option>
                <option value="fb">Facebook Pixel</option>
            </select>
            <br>
            <div v-if="form.tracking_option == 'fb'">
                <label for="">Pixel ID</label>
                <input type="text" class="form-control" v-model="form.tracking_key">
                <br>
            </div>
            <label for="">Mailketing API Key</label>
            <input type="text" class="form-control" v-model="form.mailketing_api_key">
            <br>
            <label for="">Mailketing List ID</label>
            <input type="text" class="form-control" v-model="form.mailketing_list_id">
            <br>
            <button class="btn btn-success btn-block">Simpan</button>
        </form>        
    </div>
</template>

<script>
    //import moment from 'moment'

    export default {
        props: ['device', 'id', 'campaign'],
        components: {
        },
        data() {
            return {
                form: {
                    id: "",
                    tracking_option: "",
                },
                syntax_old: "",
                syntax_explain: [],
                available_variable: "",
                quill_disabled: true
            };
        },
        mounted() {
            this.$root.$on("toggle_modal", (param) => {
                this.toggleModal(param);
            });

            this.getCampaign()
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async getCampaign() {
                let res = this.campaign

                this.form.id = res.id
                this.form.tracking_option = res.tracking_option
                this.form.tracking_key = res.tracking_key
                this.form.mailketing_api_key = res.mailketing_api_key
                this.form.mailketing_list_id = res.mailketing_list_id

            },
            async updateCampaign(){
                try{
                    let data = {
                        id: this.form.id,
                        tracking_option: this.form.tracking_option,
                        tracking_key: this.form.tracking_key,
                        mailketing_api_key: this.form.mailketing_api_key,
                        mailketing_list_id: this.form.mailketing_list_id,
                    }

                    let update_campaign = await this.$store.dispatch('campaign_update_integration', data)
                    this.$toast.success(update_campaign.data.message)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            async updateForm(){
                try{
                    let data = {
                        id: this.form.id,
                        form_type: parseInt(this.form.form_type),
                        header: this.form.header,
                    }

                    let update_campaign = await this.$store.dispatch('campaign_update_form', data)
                    this.$toast.success(update_campaign.data.message)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            copyURL(){
                navigator.clipboard.writeText(this.form.url)
                this.$toast.success("URL berhasil disalin")
            },
            decodeEscapedHTML(str){
                return str.replaceAll(/&lt;/g,'<').replaceAll(/&gt;/g,'>').replaceAll(/&amp;/g,'&');
            }
        },
    };
</script>

<style scoped>
    @import "~vue-wysiwyg/dist/vueWysiwyg.css";
    

    table {
        border: 1px solid;
    }

    .editor {
        height: 100%;
    }
</style>

<style scoped>
    .nav-link{
        cursor: pointer !important;
    }

    .thick-border {
        border: 1.5px solid #6c6c6c !important;
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 17px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 17px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
</style>